import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "MainFoot",
  setup: function setup() {
    var router = useRouter();

    var handledevice = function handledevice() {
      router.push('/product');
    };

    var handleHuiben = function handleHuiben() {
      window.open('https://bcpicbook.yuedu365.cn/#/home');
    };

    var handleQimingxing = function handleQimingxing() {
      window.open('https://qmx.yuedu365.cn/#/homenew');
    };

    var handleParter = function handleParter() {
      router.push('/partner');
    };

    var handleJoin = function handleJoin() {
      router.push('/join');
    };

    var handleBeian = function handleBeian() {
      window.open('https://beian.miit.gov.cn');
    };

    return {
      handledevice: handledevice,
      handleHuiben: handleHuiben,
      handleQimingxing: handleQimingxing,
      handleParter: handleParter,
      handleJoin: handleJoin,
      handleBeian: handleBeian
    };
  }
});