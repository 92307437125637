import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  ref: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_MainHead = _resolveComponent("MainHead");

  var _component_router_view = _resolveComponent("router-view");

  var _component_MainFoot = _resolveComponent("MainFoot");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_MainHead, {
    class: "head"
  }), _createVNode(_component_router_view), _createVNode(_component_MainFoot)], 512);
}