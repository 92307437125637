import { createApp } from "vue";
import App from "./App.vue";
import register from "./utils/register";
import router from "./route";
import axios from './api/axios'
import "lib-flexible";
const app = createApp(App);
register(app);

app.use(router);
app.mount("#app");
app.config.globalProperties.$axios=axios;  //配置axios的全局引用