import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    // 主页
    path: "/home",
    name: "Home",
    component: () => import("../views/Home/Home.vue"),
  },
  {
    // 产品介绍页
    path: "/product",
    name: "Product",
    component: () => import("../views/Product/Product.vue"),
  },
  {
    //公司简介
    path: "/introduction",
    name: "Introduction",
    component: () => import("../views/Introduction/Introduction.vue"),
  },
  {
    // 资讯
    path: "/news",
    name: "News",
    component: () => import("../views/News/News.vue"),
  },
  {
    // 合作伙伴
    path: "/partner",
    name: "Partner",
    component: () => import("../views/Partner/Partner.vue"),
  },
  {
    // 加入我们
    path: "/join",
    name: "Join",
    component: () => import("../views/Join/Join.vue"),
  },
  {
    // 资讯详情
    path: "/newsdetail",
    name: "Newsdetail",
    component: () => import("../views/NewsDetail/NewsDetail.vue"),
  },


];
//   {
//     path: "/",
//     redirect: "/home",
//   },
//   {
//     path: "/home",
//     name: "Home",
//     component: () => import("../views/Home/Home.vue"),
//   },
//   {
//     path: "/newsone",
//     name: "One",
//     component: () => import("../views/News/NewsPage/NewsOne.vue"),
//   },
//   {
//     path: "/newstwo",
//     name: "Two",
//     component: () => import("../views/News/NewsPage/NewsTwo.vue"),
//   },
//   {
//     path: "/newsthree",
//     name: "Three",
//     component: () => import("../views/News/NewsPage/NewsThree.vue"),
//   },
//   {
//     path: "/newsfour",
//     name: "Four",
//     component: () => import("../views/News/NewsPage/NewsFour.vue"),
//   },
//   {
//     path: "/equip",
//     name: "Equip",
//     component: () => import("../views/Equip/Equip.vue"),
//   },
//   {
//     path: "/news",
//     name: "News",
//     component: () => import("../views/News/News.vue"),
//   },
//   {
//     path: "/about",
//     name: "About",
//     component: () => import("../views/About/About.vue"),
//   },
// ];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
