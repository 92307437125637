import "./assets/normalize.css";
import MainFoot from "./components/Foot/MainFoot.vue";
import MainHead from "./components/Head/MainHead.vue";
import { ref, onMounted } from 'vue';
export default {
  name: "App",
  components: {
    MainHead: MainHead,
    MainFoot: MainFoot // Home,

  },
  setup: function setup() {
    var content = ref(null);

    var handleScrollx = function handleScrollx() {
      console.log("----", content.value.getBoundingClientRect().top); // if(content_one.value.getBoundingClientRect().top <= 590){
      //   active1.value = 1;
      // };
    };

    onMounted(function () {
      window.addEventListener("scroll", handleScrollx, true);
    });
    return {
      content: content,
      handleScrollx: handleScrollx
    };
  }
};