import { defineComponent, ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "MainHead",
  setup: function setup() {
    var active = ref(0);
    var router = useRouter();

    var handleHome = function handleHome() {
      active.value = 0;
      router.push('/home');
    };

    var handleProduct = function handleProduct() {
      active.value = 1;
      router.push('/product');
    };

    var handleNews = function handleNews() {
      active.value = 2;
      router.push('/news');
    };

    var handlePartner = function handlePartner() {
      active.value = 3;
      router.push('/partner');
    };

    var handleJoinUs = function handleJoinUs() {
      active.value = 4;
      router.push('/join');
    };

    var handleAboutUs = function handleAboutUs() {
      active.value = 5;
      router.push('/introduction');
    };

    watch(function () {
      return router.currentRoute.value.path;
    }, function (toPath) {
      if (toPath == '/home') {
        active.value = 0;
      } else if (toPath == '/product') {
        active.value = 1;
      } else if (toPath == '/news') {
        active.value = 2;
      } else if (toPath == '/partner') {
        active.value = 3;
      } else if (toPath == '/join') {
        active.value = 4;
      } else if (toPath == '/introduction') {
        active.value = 5;
      }
    }, {
      immediate: true,
      deep: true
    });
    var styleObject = ref({});
    onMounted(function () {
      window.addEventListener('scroll', function (e) {
        if (document.documentElement.scrollTop > 300) {
          if (active.value == 1) {
            styleObject.value = "background: rgba(20, 33, 78); transition: all 1s; ";
          } else {
            styleObject.value = "background: rgba(0, 0, 0, 0.3); transition: all 1s; ";
          }
        } else {
          styleObject.value = {};
        }
      }, true);
    });
    return {
      active: active,
      handleHome: handleHome,
      handleProduct: handleProduct,
      handleNews: handleNews,
      handlePartner: handlePartner,
      handleJoinUs: handleJoinUs,
      handleAboutUs: handleAboutUs,
      styleObject: styleObject // handleScrollx,
      // content

    };
  }
});